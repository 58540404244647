/* eslint-disable react/prop-types */

import React from "react";
import PropTypes from "prop-types";

const Logo = ({ className, color, forwards = true }) => {
  const TRANSITION_SPEED = 75;

  const getTransitionDelay = (index, length) => {
    return forwards
      ? `${index * TRANSITION_SPEED}ms`
      : `${length * TRANSITION_SPEED - index * TRANSITION_SPEED}ms`;
  };

  //

  return (
    <svg className={className} viewBox="0 0 612.33 377">
      <rect
        className="nav__logo__scalable nav__logo__scalable--paper"
        fill={color}
        x="3.43"
        y="103.85"
        width="363.96"
        height="2.26"
      />
      <rect
        className="nav__logo__scalable nav__logo__scalable--stone"
        fill={color}
        x="3.43"
        y="239.3"
        width="434.49"
        height="2.26"
      />
      <rect
        className="nav__logo__scalable nav__logo__scalable--scissors"
        fill={color}
        x="3.43"
        y="374.75"
        width="605.23"
        height="2.25"
      />

      {/* // paper // */}
      <path
        fill={color}
        d="M154.7,166.33H133.29v37H119V109.26H154.7c18.35,0,30.11,12.15,30.11,28.53s-11.76,28.54-30.11,28.54m-.8-44.26H133.29v31.31H153.9c9.91,0,16.65-5.67,16.65-15.59s-6.74-15.72-16.65-15.72"
        transform="translate(-115.59 -109.26)"
      />
      <path
        style={{ transitionDelay: getTransitionDelay(1, 4) }}
        className="nav__logo__hideable"
        fill={color}
        d="M241.25,203.32l-6.48-18.89H197.65l-6.47,18.89H176l34.48-94.06H222l34.48,94.06Zm-24.71-72.79-14.66,41.74h28.93Z"
        transform="translate(-115.59 -109.26)"
      />
      <path
        style={{ transitionDelay: getTransitionDelay(2, 4) }}
        className="nav__logo__hideable"
        fill={color}
        d="M300.92,166.33h-21.4v37H265.25V109.26h35.67c18.36,0,30.12,12.15,30.12,28.53s-11.76,28.54-30.12,28.54m-.79-44.26H279.52v31.31h20.61c9.91,0,16.64-5.67,16.64-15.59s-6.73-15.72-16.64-15.72"
        transform="translate(-115.59 -109.26)"
      />
      <polygon
        style={{ transitionDelay: getTransitionDelay(3, 4) }}
        className="nav__logo__hideable"
        fill={color}
        points="225.21 94.06 225.21 0 285.32 0 285.32 12.81 239.47 12.81 239.47 40.29 278.58 40.29 278.58 52.98 239.47 52.98 239.47 81.25 285.32 81.25 285.32 94.06 225.21 94.06"
      />
      <path
        style={{ transitionDelay: getTransitionDelay(4, 4) }}
        className="nav__logo__hideable"
        fill={color}
        d="M466.35,203.32l-19.82-39.63H428.69v39.63H414.43V109.26h36.46c17.83,0,29.2,11.49,29.2,27.47,0,13.48-8.19,22.07-18.76,25L483,203.32Zm-16.52-81.25H428.69v29.46h21.14c9.51,0,16-5.41,16-14.66s-6.47-14.8-16-14.8"
        transform="translate(-115.59 -109.26)"
      />

      {/* // stone // */}
      <path
        fill={color}
        d="M115.59,321.76c0-4,2.29-6.58,5.3-6.58a4.92,4.92,0,0,1,4.85,4.86,6.46,6.46,0,0,1-3.14,5.44c2.85,5,11.14,9.57,22.44,9.57,14.3,0,23.31-8.15,23.31-19.87s-10.87-17-22.59-21.73C131.6,287.87,119,282,119,266.86c0-13.72,11.29-23.58,27.3-23.58,14.44,0,26,8.85,26,17.44,0,3.71-2.44,6-5.15,6a5,5,0,0,1-5-5,6.65,6.65,0,0,1,2.72-5.15c-2.43-4.57-11-8.15-18.87-8.15-12.58,0-21.3,7.72-21.3,18,0,12,10.73,16.72,23.3,21.72,13.73,5.44,26,11.58,26,26.59,0,15.44-12.44,25.44-29.3,25.44-17.73,0-29.17-10.29-29.17-18.43"
        transform="translate(-115.59 -109.26)"
      />
      <polygon
        style={{ transitionDelay: getTransitionDelay(1, 4) }}
        className="nav__logo__hideable"
        fill={color}
        points="92.76 224.5 106.77 224.5 106.77 140.45 77.17 140.45 77.17 157.03 71.46 157.03 71.46 135.45 147.79 135.45 147.79 157.03 142.08 157.03 142.08 140.45 112.63 140.45 112.63 224.5 126.49 224.5 126.49 229.51 92.76 229.51 92.76 224.5"
      />
      <path
        style={{ transitionDelay: getTransitionDelay(2, 4) }}
        className="nav__logo__hideable"
        fill={color}
        d="M273.27,291.74c0-27.16,20.3-48.6,47.17-48.6s46.89,21.44,46.89,48.6-20.16,48.6-47.17,48.6c-26.73,0-46.89-21.44-46.89-48.6m88.06,0c0-24.16-17-43.46-41-43.46s-41,19.16-41,43.46c0,24.15,17.15,43.46,41,43.46s41-19.16,41-43.46"
        transform="translate(-115.59 -109.26)"
      />
      <polygon
        style={{ transitionDelay: getTransitionDelay(3, 4) }}
        className="nav__logo__hideable"
        fill={color}
        points="281.98 145.6 281.98 224.51 295.42 224.51 295.42 229.51 263.4 229.51 263.4 224.51 276.41 224.51 276.41 140.45 263.4 140.45 263.4 135.45 281.41 135.45 341.74 217.93 341.74 140.45 328.44 140.45 328.44 135.45 360.18 135.45 360.18 140.45 347.17 140.45 347.17 229.94 343.74 229.94 281.98 145.6"
      />
      <polygon
        style={{ transitionDelay: getTransitionDelay(4, 4) }}
        className="nav__logo__hideable"
        fill={color}
        points="371.74 224.5 384.74 224.5 384.74 140.45 371.74 140.45 371.74 135.45 437.93 135.45 437.93 156.32 432.2 156.32 432.2 140.45 390.6 140.45 390.6 178.91 423.91 178.91 423.91 184.06 390.6 184.06 390.6 224.5 432.2 224.5 432.2 208.65 437.93 208.65 437.93 229.51 371.74 229.51 371.74 224.5"
      />

      {/* // scissors // */}
      <path
        fill={color}
        d="M178.62,410.66h-5.36c-5.79-17.23-12.43-25.27-20.62-25.27-5.51,0-9.61,3.81-9.61,8.61,0,5.36,3.26,9.46,11,14.26,15.25,9.46,17.09,10.73,20.76,14.12,6.78,6.22,9.75,12.72,9.75,22.18,0,19.06-13.85,31.92-34.18,31.92-5.93,0-8.76-.57-16-2.83-3.1-1.13-4.52-1.56-5.79-1.56-1.83,0-2.69.57-3.82,2.13H120l-1-31.36h5.79c2.69,9.46,4.95,14,9.47,19.07,4.52,4.94,9.75,7.76,14.55,7.76,6.07,0,10.3-4.09,10.3-9.88,0-5.37-3.81-9.89-13.27-16L134,436.22c-11.29-7.2-17.23-16.81-17.23-28.1,0-17.52,12.72-29.94,30.51-29.94,4.52,0,6.92.42,14,2.4,4.24,1.13,6.5,1.69,8.19,1.69,1.84,0,2.55-.42,3.82-2.11h4Z"
        transform="translate(-115.59 -109.26)"
      />
      <path
        style={{ transitionDelay: getTransitionDelay(1, 7) }}
        className="nav__logo__hideable"
        fill={color}
        d="M264.7,416.17h-4.94c-2.4-10.74-5.08-16.95-9.74-23-3.82-4.94-9.18-7.9-14.13-7.9-9.46,0-14.69,10.3-14.69,28.52,0,26.41,10.46,44.77,25.43,44.77,7.9,0,13.13-3.68,19.34-13l3.68,2.55c-3.68,7.9-5.51,10.87-8.9,15a38.72,38.72,0,0,1-9,7.76,43.84,43.84,0,0,1-21.61,5.65c-23.87,0-39.54-18.22-39.54-45.89,0-13.14,4.1-26.7,10.73-36.16,7.63-10.73,18.51-16.24,32.2-16.24,5.51,0,8.19.42,16,2.54a17.4,17.4,0,0,0,3,.56c2.11,0,3.1-.7,4.09-2.82h4.67Z"
        transform="translate(-115.59 -109.26)"
      />
      <path
        style={{ transitionDelay: getTransitionDelay(2, 7) }}
        className="nav__logo__hideable"
        fill={color}
        d="M319.34,474.22h-43.5V469.7c5.23-.29,6.22-1.7,6.22-8.91V393.57c0-7.2-1-8.48-6.36-8.9v-4.51h43.64v4.51c-5.36.42-6.21,1.84-6.21,8.9v67.09c0,7.34.85,8.61,6.21,9Z"
        transform="translate(-115.59 -109.26)"
      />
      <path
        style={{ transitionDelay: getTransitionDelay(3, 7) }}
        className="nav__logo__hideable"
        fill={color}
        d="M389.09,410.66h-5.36c-5.8-17.23-12.44-25.27-20.63-25.27-5.51,0-9.6,3.81-9.6,8.61,0,5.36,3.25,9.46,11,14.26,15.26,9.46,17.09,10.73,20.77,14.12,6.77,6.22,9.74,12.72,9.74,22.18,0,19.06-13.84,31.92-34.18,31.92-5.93,0-8.75-.57-16-2.83-3.1-1.13-4.52-1.56-5.79-1.56-1.83,0-2.68.57-3.81,2.13h-4.8l-1-31.36h5.79c2.69,9.46,5,14,9.46,19.07,4.53,4.94,9.75,7.76,14.55,7.76,6.08,0,10.31-4.09,10.31-9.88,0-5.37-3.81-9.89-13.28-16l-11.86-7.63c-11.29-7.2-17.23-16.81-17.23-28.1,0-17.52,12.71-29.94,30.51-29.94,4.51,0,6.92.42,14,2.4,4.23,1.13,6.49,1.69,8.19,1.69s2.54-.42,3.82-2.11h3.95Z"
        transform="translate(-115.59 -109.26)"
      />
      <path
        style={{ transitionDelay: getTransitionDelay(4, 7) }}
        className="nav__logo__hideable"
        fill={color}
        d="M462.37,410.66H457c-5.79-17.23-12.43-25.27-20.61-25.27-5.52,0-9.61,3.81-9.61,8.61,0,5.36,3.25,9.46,11,14.26,15.26,9.46,17.09,10.73,20.76,14.12,6.78,6.22,9.75,12.72,9.75,22.18,0,19.06-13.84,31.92-34.17,31.92-5.94,0-8.76-.57-16-2.83-3.11-1.13-4.52-1.56-5.79-1.56-1.84,0-2.69.57-3.82,2.13h-4.8l-1-31.36h5.79c2.69,9.46,4.95,14,9.46,19.07,4.52,4.94,9.75,7.76,14.55,7.76,6.07,0,10.31-4.09,10.31-9.88,0-5.37-3.82-9.89-13.27-16l-11.87-7.63c-11.3-7.2-17.23-16.81-17.23-28.1,0-17.52,12.71-29.94,30.5-29.94,4.52,0,6.92.42,14,2.4,4.23,1.13,6.5,1.69,8.19,1.69s2.54-.42,3.81-2.11h4Z"
        transform="translate(-115.59 -109.26)"
      />
      <path
        style={{ transitionDelay: getTransitionDelay(5, 7) }}
        className="nav__logo__hideable"
        fill={color}
        d="M518.64,476.47c-25.55,0-44.34-21-44.34-49.71,0-28.39,18.22-48.58,43.78-48.58,26.27,0,45.06,20.19,45.06,48.29,0,29-18.79,50-44.5,50M526,390.75c-1.27-3.39-4.38-5.93-7.35-5.93-5.35,0-8.75,5.93-10,17.23a223.62,223.62,0,0,0-1.27,25.13c0,17.94,1.27,29.66,4,36.44,1.26,3.39,4.36,5.93,7.33,5.93,5.38,0,8.76-5.79,10-17.23a222.86,222.86,0,0,0,1.13-25.14c0-17.93-1.13-29.65-3.81-36.43"
        transform="translate(-115.59 -109.26)"
      />
      <path
        style={{ transitionDelay: getTransitionDelay(6, 7) }}
        className="nav__logo__hideable"
        fill={color}
        d="M621.18,474.21l-1.56-4.94a150.1,150.1,0,0,1-4.8-20.34c-3.25-16.8-4.66-19.62-10-19.62h-.28v31.35c-.14,7.34.85,8.61,6.22,9v4.52H567.22v-4.52c5.09-.27,6.08-1.69,6.08-8.89V393.57c0-7.2-1-8.47-6.21-8.9v-4.52h52.53c16.81,0,28.67,9.89,28.67,24,0,10.44-6.21,17.36-19.2,21.46,8.75,4.66,11.71,8.33,13.7,16.52L647,458.54c2.26,9.32,3.53,10.88,8.34,11.15v4.52Zm-1.27-69.76c0-9.75-5.09-17.94-11-17.94-3,0-4.52,2-4.52,5.79v30.23c10.31-.15,15.54-6.22,15.54-18.08"
        transform="translate(-115.59 -109.26)"
      />
      <path
        style={{ transitionDelay: getTransitionDelay(7, 7) }}
        className="nav__logo__hideable"
        fill={color}
        d="M722,410.66h-5.37c-5.79-17.23-12.43-25.27-20.62-25.27-5.51,0-9.6,3.81-9.6,8.61,0,5.36,3.24,9.46,11,14.26,15.24,9.46,17.08,10.73,20.76,14.12,6.77,6.22,9.74,12.72,9.74,22.18,0,19.06-13.84,31.92-34.18,31.92-5.93,0-8.76-.57-16-2.83-3.1-1.13-4.52-1.56-5.79-1.56-1.84,0-2.68.57-3.81,2.13h-4.81l-1-31.36h5.79c2.68,9.46,4.94,14,9.46,19.07,4.52,4.94,9.75,7.76,14.55,7.76,6.07,0,10.31-4.09,10.31-9.88,0-5.37-3.82-9.89-13.28-16l-11.86-7.63c-11.3-7.2-17.23-16.81-17.23-28.1,0-17.52,12.7-29.94,30.5-29.94,4.53,0,6.92.42,14,2.4,4.24,1.13,6.5,1.69,8.2,1.69s2.53-.42,3.81-2.11h4Z"
        transform="translate(-115.59 -109.26)"
      />
    </svg>
  );
};

Logo.defaultProps = {
  className: ``,
  color: ``
};

Logo.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default Logo;
