import React, { Component, createContext } from "react";
import PropTypes from "prop-types";
import locales from "~data/locales";

export const AppContext = createContext({});

class AppProvider extends Component {
  state = {
    defaultNavActive: true,
    locale: {},
    languageWidgetColor: `white`,
    menuActive: false,
    navColor: `white`,
    newsActive: false,
    navAnimating: false,
    navFocused: false,
    viewed: false,
    weChatActive: false
  };

  //

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log(`%c PSS `, `background: #ffffff; color: #000000`);
  }

  //

  getDefaultLocale = () => {
    let defaultLocale;

    Object.keys(locales).forEach(localeKey => {
      if (defaultLocale) {
        return;
      }

      const locale = locales[localeKey];

      if (locale.default) {
        defaultLocale = locale;
      }
    });

    return defaultLocale;
  };

  getLocalePath = suffix => {
    const { locale } = this.state;

    if (!locale || !locale.path || locale.default) {
      return suffix;
    }

    if (!suffix.startsWith(`/`)) {
      suffix = `/${suffix}`;
    }

    const path = `/${locale.path}${suffix}`;

    return path;
  };

  getLocaleFromFrontmatterNode = node => {
    if (!node || !node.fields || !node.fields.slug) {
      return false;
    }

    const nodeLocalePath = node.fields.slug.split(`.`)[1].replace(`/`, ``);
    let matchedLocale;

    Object.keys(locales).forEach(localeKey => {
      if (matchedLocale) {
        return;
      }

      const locale = locales[localeKey];

      if (nodeLocalePath === locale.path) {
        matchedLocale = locale;
      }
    });

    return matchedLocale;
  };

  //

  setDefaultNavActive = defaultNavActive => {
    this.setState({
      defaultNavActive
    });
  };

  setLocale = locale => {
    this.setState({
      locale
    });
  };

  setLanguageWidgetColor = languageWidgetColor => {
    this.setState({
      languageWidgetColor
    });
  };

  setMenuActive = menuActive => {
    let { newsActive } = this.state;

    if (menuActive && newsActive) {
      newsActive = false;
    }

    this.setState({
      menuActive,
      newsActive
    });
  };

  setNavColor = navColor => {
    this.setState({
      navColor
    });
  };

  setNewsActive = newsActive => {
    let { menuActive } = this.state;

    if (newsActive && menuActive) {
      menuActive = false;
    }

    this.setState({
      menuActive,
      newsActive
    });
  };

  setNavAnimating = navAnimating => {
    this.setState({
      navAnimating
    });
  };

  setNavFocused = navFocused => {
    this.setState({
      navFocused
    });
  };

  setViewed = viewed => {
    this.setState({
      viewed
    });
  };

  setWeChatActive = weChatActive => {
    this.setState({
      weChatActive
    });
  };

  //

  render() {
    return (
      <AppContext.Provider
        value={{
          defaultNavActive: this.state.defaultNavActive,
          locale: this.state.locale,
          languageWidgetColor: this.state.languageWidgetColor,
          menuActive: this.state.menuActive,
          navColor: this.state.navColor,
          newsActive: this.state.newsActive,
          navAnimating: this.state.navAnimating,
          navFocused: this.state.navFocused,
          viewed: this.state.viewed,
          weChatActive: this.state.weChatActive,
          //
          getDefaultLocale: this.getDefaultLocale,
          getLocaleFromFrontmatterNode: this.getLocaleFromFrontmatterNode,
          getLocalePath: this.getLocalePath,
          //
          setDefaultNavActive: this.setDefaultNavActive,
          setLocale: this.setLocale,
          setLanguageWidgetColor: this.setLanguageWidgetColor,
          setMenuActive: this.setMenuActive,
          setNavColor: this.setNavColor,
          setNewsActive: this.setNewsActive,
          setNavAnimating: this.setNavAnimating,
          setNavFocused: this.setNavFocused,
          setViewed: this.setViewed,
          setWeChatActive: this.setWeChatActive
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
