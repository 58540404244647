/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import { graphql, StaticQuery } from "gatsby";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Cross from "~components/svg/Cross";

class WeChatComponent extends Component {
  state = {};

  componentDidMount() {
    if (window) {
      window.addEventListener(`keydown`, this.handleKeydown, false);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener(`keydown`, this.handleKeydown, false);
    }
  }

  //

  handleKeydown = e => {
    const { appContext } = this.props;

    switch (e.keyCode) {
      case 27:
        appContext.setWeChatActive(false);

        break;

      default:
        break;
    }
  };

  //

  render() {
    const { allDataYaml, appContext } = this.props;
    const { weChatActive } = appContext;

    let loadedWeChatQR = ``;

    allDataYaml.edges.forEach(({ node }) => {
      if (node.qr && node.qr.length > 0 && node.qr[0].weChatQR) {
        loadedWeChatQR = node.qr[0].weChatQR;
      }
    });

    return (
      <div
        className={`wechat ${
          weChatActive ? `active` : ``
        } w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-50 flex items-center justify-center`}
      >
        <div className="w-full h-full absolute top-0 right-0 bottom-0 left-0 bg-black opacity-25"></div>

        <article className="w-1/2 relative flex flex-col items-center justify-center py-12 bg-white">
          <button
            type="button"
            onClick={() => {
              appContext.setWeChatActive(false);
            }}
          >
            <Cross
              className="w-16 h-16 m-2 p-4 absolute top-0 right-0 cursor-pointer"
              color="black"
            />
          </button>

          <h3 className="f4">Follow us on WeChat</h3>

          <img
            className="w-48 relative block my-16"
            src={loadedWeChatQR}
            alt="WeChat QR Code"
          />

          <p className="w-1/2 relative b2 text-center">
            Keep up to date on the latest news and activity in our Shanghai
            studio.
          </p>
        </article>
      </div>
    );
  }
}

const query = graphql`
  query QR {
    allDataYaml {
      edges {
        node {
          qr {
            weChatQR
          }
        }
      }
    }
  }
`;

const WeChat = ({ clipped, color }) => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);

  return (
    <StaticQuery
      query={query}
      render={({ allDataYaml }) => (
        <WeChatComponent
          allDataYaml={allDataYaml}
          appContext={appContext}
          clipped={clipped}
          color={color}
          documentContext={documentContext}
        />
      )}
    />
  );
};

export default WeChat;
