/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import LocaleLink from "~components/LocaleLink";
import Logo from "~components/svg/Logo";

class NavComponent extends Component {
  state = {
    logoActive: false,
    logoAnimating:
      typeof window !== `undefined` && window?.location?.pathname === `/`
  };

  componentDidMount() {
    if (this.state.logoAnimating) {
      setTimeout(() => {
        if (!this.state.logoAnimating) {
          return;
        }

        this.setState({
          logoAnimating: false
        });
      }, 1500);
    }
  }

  clearNavs = () => {
    const { appContext } = this.props;

    clearAllBodyScrollLocks();

    appContext.setMenuActive(false);
    appContext.setNewsActive(false);
  };

  getLogoTextJSX = content => {
    const { appContext } = this.props;
    const { navAnimating } = appContext;
    const { logoActive } = this.state;

    const chars = content.split(``);
    const delay = 200;
    const jsx = [];
    const speed = 50;

    chars.forEach((char, index) => {
      const charIndex = index;

      let transitionDelay = `${(index + 1) * speed}ms`;

      if (!logoActive && !navAnimating) {
        transitionDelay = `${(chars.length - index) * speed}ms`;
      }

      const className = `${
        index === 0 ? `` : `opacity-${navAnimating || logoActive ? `100` : `0`}`
      }`;

      jsx.push(
        <span
          key={`mask-char-${charIndex}`}
          className={`transition-opacity ${className}`}
          style={{
            transitionDelay
          }}
        >
          {char}
        </span>
      );
    });

    return (
      <h4
        className="nav__logo__text animation-fade f4 untited-sans uppercase"
        style={{ animationDelay: `${delay}ms` }}
      >
        {jsx}
      </h4>
    );
  };

  showNews = () => {
    const { appContext } = this.props;

    disableBodyScroll();

    appContext.setNewsActive(true);
  };

  toggleMenu = () => {
    const { appContext } = this.props;

    appContext.setMenuActive(!appContext.menuActive);
  };

  toggleTouchMenu = () => {
    const { appContext } = this.props;
    let { menuActive } = appContext;

    menuActive = !menuActive;

    if (menuActive) {
      disableBodyScroll();
    } else {
      clearAllBodyScrollLocks();
    }

    appContext.setMenuActive(menuActive);
  };

  //

  render() {
    const { appContext, clipped, color, documentContext } = this.props;
    const {
      defaultNavActive,
      menuActive,
      navAnimating,
      navColor,
      navFocused,
      newsActive
    } = appContext;
    const { device, scrollingDown } = documentContext;

    //

    let logoVisible = true;

    if (typeof window !== `undefined`) {
      logoVisible =
        window?.location?.pathname !== `/work` &&
        window?.location?.pathname !== `/work/`;
    }

    //

    return (
      <>
        {(device === `desktop` && (
          <nav
            className={`nav ${navAnimating ? `animating` : ``} ${
              clipped ? `z-50` : `z-40`
            } ${!clipped && !defaultNavActive ? `hidden` : ``} ${
              navFocused ? `focused` : ``
            } ${clipped && color ? `text-${color}` : `text-${navColor}`} ${
              newsActive ? `hidden` : ``
            } w-screen h-screen fixed top-0 bottom-0 left-0 pointer-events-none`}
            onMouseOver={() => appContext.setNavFocused(true)}
            onFocus={() => appContext.setNavFocused(true)}
            onMouseOut={() => appContext.setNavFocused(false)}
            onBlur={() => appContext.setNavFocused(false)}
          >
            <div
              className={`nav__inner h-full relative flex flex-col items-start justify-between py-8 ${
                navAnimating ? `` : `pointer-events-auto`
              }`}
            >
              {(!navAnimating && (
                <div
                  className={`${!menuActive ? `animation-appear-down` : ``}`}
                >
                  <h4 className="nav__link nav__link--menu-toggle transition-opacity relative pointer-events-none b2 mb-4">
                    Menu
                  </h4>

                  {!menuActive && (
                    <ul className="nav__links relative">
                      <li className="nav__link nav__link--hidden relative cursor-pointer b2">
                        <LocaleLink
                          to="/"
                          onClick={this.clearNavs}
                          className="transition-opacity"
                        >
                          Home
                        </LocaleLink>
                      </li>

                      <li className="nav__link nav__link--hidden relative cursor-pointer b2">
                        <LocaleLink
                          to="/studio"
                          onClick={this.clearNavs}
                          className="transition-opacity"
                        >
                          Studio
                        </LocaleLink>
                      </li>

                      <li className="nav__link nav__link--hidden relative cursor-pointer b2">
                        <LocaleLink
                          to="/services"
                          onClick={this.clearNavs}
                          className="transition-opacity"
                        >
                          Services
                        </LocaleLink>
                      </li>

                      <li className="nav__link nav__link--hidden relative cursor-pointer b2">
                        <LocaleLink
                          to="/work"
                          onClick={this.clearNavs}
                          className="transition-opacity"
                        >
                          Work
                        </LocaleLink>
                      </li>

                      <li className="nav__link nav__link--hidden relative cursor-pointer b2">
                        <LocaleLink
                          to="/production"
                          onClick={this.clearNavs}
                          className="transition-opacity"
                        >
                          Production
                        </LocaleLink>
                      </li>

                      <li className="nav__link nav__link--hidden relative cursor-pointer b2">
                        <LocaleLink
                          to="/contact"
                          onClick={this.clearNavs}
                          className="transition-opacity"
                        >
                          Contact
                        </LocaleLink>
                      </li>

                      <li className="nav__link nav__link--hidden relative pointer-events-none b2">
                        -
                      </li>

                      <li className="nav__link nav__link--hidden relative cursor-pointer b2">
                        <LocaleLink
                          to="/nowthem"
                          onClick={this.clearNavs}
                          className="transition-opacity"
                        >
                          Now&amp;Them
                        </LocaleLink>
                      </li>

                      <li className="nav__link nav__link--hidden relative cursor-pointer b2">
                        <button
                          type="button"
                          onClick={() => {
                            this.clearNavs();
                            this.showNews();
                          }}
                          className="transition-opacity"
                        >
                          News
                        </button>
                      </li>

                      <li className="nav__link nav__link--hidden relative cursor-pointer b2">
                        <LocaleLink
                          to="/play"
                          onClick={this.clearNavs}
                          className="transition-opacity"
                        >
                          Play
                        </LocaleLink>
                      </li>

                      {/* <li className="nav__link nav__link--hidden relative cursor-pointer b2">
                        <LocaleLink
                          to="/"
                          onClick={this.clearNavs}
                          className="transition-opacity"
                        >
                          Now&amp;Them
                        </LocaleLink>
                      </li> */}
                    </ul>
                  )}
                </div>
              )) || <div className="opacity-0">.</div>}

              <div
                className={`nav__logo ${
                  this.state.logoAnimating ? `nav__logo--animating` : ``
                } w-full h-16 relative ${logoVisible ? `block` : `hidden`}`}
                onMouseOver={() => this.setState({ logoActive: true })}
                onFocus={() => this.setState({ logoActive: true })}
                onMouseOut={() => this.setState({ logoActive: false })}
                onBlur={() => this.setState({ logoActive: false })}
              >
                <LocaleLink to="/">
                  <Logo
                    className="animation-appear animation-delay-2 h-16"
                    forwards={!this.state.logoActive}
                    color={navColor}
                  />
                </LocaleLink>
              </div>
            </div>
          </nav>
        )) || (
          <>
            {/* <div className="nav__logo transition-opacity fixed bottom-0 left-0 z-50 p-4 xs:p-3 pointer-events-none text-white">
              {this.getLogoTextJSX(`PAPER`, true)}
              {this.getLogoTextJSX(`STONE`, true)}
              {this.getLogoTextJSX(`SCISSORS.`, true)}
            </div> */}

            <div
              className={`nav__logo ${
                this.state.logoAnimating ? `nav__logo--animating` : ``
              } nav__logo transition-opacity fixed bottom-0 left-0 z-50 p-4 xs:p-3 pointer-events-none text-white`}
            >
              <LocaleLink to="/">
                <Logo
                  className="animation-appear animation-delay-2 h-16"
                  forwards={!this.state.logoActive}
                  color={navColor}
                />
              </LocaleLink>
            </div>

            {!navAnimating && !newsActive && (
              <div
                className={`touch-nav ${menuActive ? `menu-active` : ``} ${
                  scrollingDown ? `` : `scrolling-up`
                } ${
                  clipped ? `clipped` : ``
                }  w-full fixed top-0 right-0 left-0 z-40 py-4`}
              >
                <div className="grid">
                  <div className="grid-end-22 grid-start-2 flex items-center justify-between">
                    <LocaleLink
                      to="/"
                      className="touch-nav__title py-2 f4 text-black"
                    >
                      PSS.
                    </LocaleLink>

                    <button
                      type="button"
                      className={`touch-nav__button ${
                        clipped ? `animation-delay-2` : ``
                      } animation-appear-left ${
                        menuActive ? `active` : ``
                      } relative gpu py-3 px-2`}
                      onClick={this.toggleTouchMenu}
                    >
                      <ul className="pointer-events-none">
                        <li
                          className={`touch-nav__button__line touch-nav__button__line--0 transition-transform w-6 relative bg-${color}`}
                        ></li>

                        <li
                          className={`touch-nav__button__line touch-nav__button__line--1 transition-transform w-6 relative bg-${color}`}
                        ></li>

                        <li
                          className={`touch-nav__button__line touch-nav__button__line--2 transition-transform w-6 relative bg-${color}`}
                        ></li>
                      </ul>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

NavComponent.propTypes = {
  clipped: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired
};

const Nav = ({ clipped, color }) => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);

  return (
    <NavComponent
      appContext={appContext}
      clipped={clipped}
      color={color}
      documentContext={documentContext}
    />
  );
};

Nav.defaultProps = {
  clipped: false,
  color: `white`
};

Nav.propTypes = {
  clipped: PropTypes.bool,
  color: PropTypes.string
};

export default Nav;
