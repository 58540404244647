/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import { graphql, StaticQuery } from "gatsby";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import FullHeight from "~components/FullHeight";
import LocaleLink from "~components/LocaleLink";

class MenuComponent extends Component {
  componentDidMount() {
    this.addKeyupListeners();
    this.addScrollListeners();
  }

  //

  addKeyupListeners = () => {
    window.addEventListener(`keyup`, e => {
      switch (e.keyCode) {
        case 27:
          this.close();

          break;

        default:
          break;
      }
    });
  };

  addScrollListeners = () => {
    document.addEventListener(`scroll`, () => {
      this.close();
    });
  };

  //

  close = () => {
    const { appContext } = this.props;

    appContext.setMenuActive(false);

    clearAllBodyScrollLocks();
  };

  showNews = () => {
    const { appContext } = this.props;

    this.close();

    disableBodyScroll();

    appContext.setNewsActive(true);
  };

  //

  render() {
    const { appContext, documentContext } = this.props;
    const { menuActive } = appContext;
    const { device } = documentContext;

    return (
      <>
        {device !== `desktop` && (
          <FullHeight
            className={`menu ${
              menuActive ? `active` : `pointer-events-none`
            } transition-opacity w-screen h-screen z-40 bg-white`}
            position="fixed"
          >
            {menuActive && (
              <nav className="h-full grid">
                <div className="h-full grid-end-22 grid-start-2 relative z-10 pt-24">
                  <ul className="menu__links relative">
                    <li
                      className="animation-appear-right py-1 f2"
                      style={{ animationDelay: `125ms` }}
                    >
                      <LocaleLink
                        to="/"
                        className="menu__link"
                        onClick={this.close}
                      >
                        <span>Home</span>
                        <span className="menu__link__arrow transition-opacity-transform ml-4">
                          →
                        </span>
                      </LocaleLink>
                    </li>

                    <li
                      className="animation-appear-right py-1 f2"
                      style={{ animationDelay: `150ms` }}
                    >
                      <LocaleLink
                        to="/studio"
                        className="menu__link"
                        onClick={this.close}
                      >
                        <span>Studio</span>
                        <span className="menu__link__arrow transition-opacity-transform ml-4">
                          →
                        </span>
                      </LocaleLink>
                    </li>

                    <li
                      className="animation-appear-right py-1 f2"
                      style={{ animationDelay: `175ms` }}
                    >
                      <LocaleLink
                        to="/services"
                        className="menu__link"
                        onClick={this.close}
                      >
                        <span>Services</span>
                        <span className="menu__link__arrow transition-opacity-transform ml-4">
                          →
                        </span>
                      </LocaleLink>
                    </li>

                    <li
                      className="animation-appear-right py-1 f2"
                      style={{ animationDelay: `200ms` }}
                    >
                      <LocaleLink
                        to="/work"
                        className="menu__link"
                        onClick={this.close}
                      >
                        <span>Work</span>
                        <span className="menu__link__arrow transition-opacity-transform ml-4">
                          →
                        </span>
                      </LocaleLink>
                    </li>

                    <li
                      className="animation-appear-right py-1 f2"
                      style={{ animationDelay: `225ms` }}
                    >
                      <LocaleLink
                        to="/production"
                        className="menu__link"
                        onClick={this.close}
                      >
                        <span>Production</span>
                        <span className="menu__link__arrow transition-opacity-transform ml-4">
                          →
                        </span>
                      </LocaleLink>
                    </li>

                    <li
                      className="animation-appear-right py-1 f2"
                      style={{ animationDelay: `250ms` }}
                    >
                      <LocaleLink
                        to="/contact"
                        className="menu__link"
                        onClick={this.close}
                      >
                        <span>Contact</span>
                        <span className="menu__link__arrow transition-opacity-transform ml-4">
                          →
                        </span>
                      </LocaleLink>
                    </li>
                  </ul>

                  <ul className="menu__links relative mt-8">
                    <li
                      className="animation-appear-right f3"
                      style={{ animationDelay: `275ms` }}
                    >
                      <LocaleLink
                        to="/nowthem"
                        className="menu__link"
                        onClick={this.close}
                      >
                        <span>Now&amp;Them</span>
                        <span className="menu__link__arrow transition-opacity-transform ml-4">
                          →
                        </span>
                      </LocaleLink>
                    </li>

                    <li
                      className="animation-appear-right f3"
                      style={{ animationDelay: `300ms` }}
                    >
                      <button
                        type="button"
                        className="menu__link"
                        onClick={this.showNews}
                      >
                        <span>News</span>
                        <span className="menu__link__arrow transition-opacity-transform ml-4">
                          →
                        </span>
                      </button>
                    </li>

                    <li
                      className="animation-appear-right f3"
                      style={{ animationDelay: `325ms` }}
                    >
                      <LocaleLink
                        to="/play"
                        className="menu__link"
                        onClick={this.close}
                      >
                        <span>Play</span>
                        <span className="menu__link__arrow transition-opacity-transform ml-4">
                          →
                        </span>
                      </LocaleLink>
                    </li>
                  </ul>
                </div>
              </nav>
            )}
          </FullHeight>
        )}
      </>
    );
  }
}

const query = graphql`
  query Menu {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "project-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }

          frontmatter {
            title

            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            backgroundImageAlt
            backgroundImageFontColor
          }
        }
      }
    }
  }
`;

const Menu = () => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);

  return (
    <StaticQuery
      query={query}
      render={({ allMarkdownRemark }) => (
        <MenuComponent
          allMarkdownRemark={allMarkdownRemark}
          appContext={appContext}
          documentContext={documentContext}
        />
      )}
    />
  );
};

export default Menu;
