/* eslint-disable react/prop-types */

import React, { useContext } from "react";
import Div100vh from "react-div-100vh";
import { PropTypes } from "prop-types";
import { DocumentContext } from "~context/DocumentContext";

const FullHeight = ({ children, className, position }) => {
  const documentContext = useContext(DocumentContext);
  const { device } = documentContext;

  return (
    <>
      {(device === `desktop` && (
        <div
          className={`${className} w-screen h-screen ${position} top-0 right-0 bottom-0 left-0`}
        >
          {children}
        </div>
      )) || (
        <Div100vh
          className={`${className} w-screen ${position} top-0 right-0 bottom-0 left-0`}
        >
          {children}
        </Div100vh>
      )}
    </>
  );
};

FullHeight.defaultProps = {
  className: ``,
  position: `absolute`
};

FullHeight.propTypes = {
  className: PropTypes.string,
  position: PropTypes.string
};

export default FullHeight;
