/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import { graphql, StaticQuery } from "gatsby";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";

class NewsComponent extends Component {
  state = {
    readMoreActiveIndices: []
  };

  componentDidMount() {
    this.addKeyupListeners();
  }

  //

  addKeyupListeners = () => {
    window.addEventListener(`keyup`, e => {
      switch (e.keyCode) {
        case 27:
          this.close();

          break;

        default:
          break;
      }
    });
  };

  //

  close = () => {
    const { appContext } = this.props;

    appContext.setNewsActive(false);

    clearAllBodyScrollLocks();
  };

  //

  render() {
    const { allDataYaml, appContext, documentContext } = this.props;
    const { readMoreActiveIndices } = this.state;

    const { newsActive } = appContext;
    const { device } = documentContext;

    const articles = [];

    allDataYaml.edges.forEach(edge => {
      if (!edge.node || !edge.node.news) {
        return;
      }

      edge.node.news.forEach(newsArticle => {
        articles.push(newsArticle);
      });
    });

    return (
      <div
        className={`news ${
          newsActive ? `active` : `pointer-events-none`
        } w-screen h-screen fixed top-0 bottom-0 left-0 z-50`}
      >
        <div
          role="presentation"
          className={`transition-opacity w-screen h-screen absolute top-0 right-0 bottom-0 left-0 z-10 ${
            newsActive ? `opacity-50` : `opacity-0 pointer-events-none`
          } cursor-pointer bg-black`}
          onClick={this.close}
        ></div>

        <div
          className={`news__inner ${
            newsActive ? `opacity-100` : `opacity-0`
          } transition-opacity-transform h-full absolute left-0 z-20 bg-white`}
        >
          <h3 className="pb-8 f4">
            The latest news from our studios and around the world
          </h3>

          <div
            className={`news__back ${
              device === `desktop` ? `` : `ml-8`
            } absolute top-0 left-0 flex items-center justify-center pt-5`}
          >
            <button
              type="button"
              className="news__back-button relative flex p-2 b2"
              onClick={this.close}
            >
              <span className="news__back-button__arrow transition-transform relative block mr-1">
                ←
              </span>
              <span>Back</span>
            </button>
          </div>

          <ul className="news__scroll w-full h-full relative overflow-y-scroll pb-48">
            {newsActive &&
              articles.map((article, index) => {
                let articleContent = article.content;
                let articleTruncated = false;

                if (
                  articleContent.length > 200 &&
                  !readMoreActiveIndices.includes(index)
                ) {
                  articleTruncated = true;

                  articleContent = articleContent.substr(0, 200);

                  articleContent = articleContent.substr(
                    0,
                    Math.min(
                      articleContent.length,
                      articleContent.lastIndexOf(` `)
                    )
                  );

                  articleContent = `${articleContent} ...`;
                }

                return (
                  <li
                    key={article.title}
                    className="news__scroll__item w-full relative"
                  >
                    {newsActive && article?.image && (
                      <img
                        className="animation-appear-left w-full relative block"
                        style={{ animationDelay: `${index * 50 + 50}ms` }}
                        src={article.image}
                        alt={article.title}
                      />
                    )}

                    {newsActive && article?.videoUrl && (
                      <video
                        className="w-full relative block"
                        autoPlay
                        loop
                        muted
                        playsInline
                      >
                        <source src={article.videoUrl} />
                      </video>
                    )}

                    <article className="relative pt-2 pb-12">
                      <h5
                        className="animation-appear-left absolute top-0 right-0 pt-4 b3"
                        style={{ animationDelay: `${index * 50 + 75}ms` }}
                      >
                        {article.date}
                      </h5>

                      <h2
                        className="animation-appear-left pt-1 pr-12 f5"
                        style={{ animationDelay: `${index * 50 + 100}ms` }}
                      >
                        {article.title}
                      </h2>

                      <p
                        className="animation-appear-left mt-4 b2 whitespace-pre-wrap"
                        dangerouslySetInnerHTML={{ __html: articleContent }}
                        style={{ animationDelay: `${index * 50 + 125}ms` }}
                      ></p>

                      {articleTruncated && (
                        <>
                          {!readMoreActiveIndices.includes(index) && (
                            <button
                              type="button"
                              className="animation-appear-left py-4 b2 underline"
                              style={{
                                animationDelay: `${index * 50 + 150}ms`
                              }}
                              onClick={() => {
                                this.setState(prevState => ({
                                  readMoreActiveIndices: [
                                    ...prevState.readMoreActiveIndices,
                                    index
                                  ]
                                }));
                              }}
                            >
                              Read more
                            </button>
                          )}
                        </>
                      )}
                    </article>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
  }
}

const query = graphql`
  query News {
    allDataYaml {
      edges {
        node {
          news {
            content
            date
            image
            title
            videoUrl
          }
        }
      }
    }
  }
`;

const News = () => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);

  return (
    <StaticQuery
      query={query}
      render={({ allDataYaml }) => (
        <NewsComponent
          allDataYaml={allDataYaml}
          appContext={appContext}
          documentContext={documentContext}
        />
      )}
    />
  );
};

export default News;
