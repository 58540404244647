import React, { useContext } from "react";
import { PropTypes } from "prop-types";
import { Link } from "gatsby";
import { AppContext } from "~context/AppContext";

const LocaleLink = ({
  children,
  className,
  onBlur,
  onClick,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  onMouseOut,
  onMouseOver,
  to
}) => {
  const appContext = useContext(AppContext);

  let localePath = appContext.getLocalePath(to);

  if (!localePath.startsWith(`/`)) {
    localePath = `/${localePath}`;
  }

  return (
    <Link
      to={localePath}
      className={className}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
    >
      {children}
    </Link>
  );
};

LocaleLink.defaultProps = {
  className: ``,
  onBlur: () => {},
  onClick: () => {},
  onFocus: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onMouseOut: () => {},
  onMouseOver: () => {}
};

LocaleLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseOut: PropTypes.func,
  onMouseOver: PropTypes.func,
  to: PropTypes.string.isRequired
};

export default LocaleLink;
