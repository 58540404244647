const defaultLanguage = process.env.GATSBY_DEFAULT_LANGUAGE || `en`;

module.exports = {
  en: {
    path: `en`,
    locale: `English`,
    default: defaultLanguage === `en`
  },
  cn: {
    path: `cn`,
    locale: `中文`,
    default: defaultLanguage === `cn`
  }
};
